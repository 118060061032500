<template>
    <div>
        <v-data-table
                :headers="headers"
                :height="Salesitems.length < 2 ? '150px' : null"
                :items="Salesitems"
                calculate-widths
                class="mt-3 appic-table-light specification-table"
                dense
                disable-pagination
                disable-sort
                hide-default-header
                hide-default-footer
                item-key="index"
                single-select
        >
            <template v-slot:header="{ props: { headers } }">
                <tr>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[0].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[1].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[2].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[3].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[4].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[5].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[6].text }}*</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[7].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[8].text + (Contract__currency_id != Invoice__currency_id ? '(' + allCurrencies.find((currency)=>currency.Currency.id == Contract__currency_id).Currency.code+')' : '')}}*</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[9].text + (Contract__currency_id != Invoice__currency_id ? '(' + allCurrencies.find((currency)=>currency.Currency.id == Contract__currency_id).Currency.code+')' : '')}}</th>
                    <th class="grey lighten-3 pa-1 text-left" v-if="Contract__currency_id != Invoice__currency_id">{{ headers[10].text + '(' + allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.code + ')' }}</th>
                    <th class="grey lighten-3 pa-1 text-left" v-if="Contract__currency_id != Invoice__currency_id">{{ headers[11].text + '(' + allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.code + ')' }}</th>
                    <th class="grey lighten-3 pa-1 text-left" v-if="Contract__currency_id != Invoice__currency_id">{{ headers[12].text}}</th>
                    <th class="grey lighten-3 pa-1 text-left" v-if="Contract__currency_id != Invoice__currency_id">{{ headers[13].text}}</th>
                    <th class="grey lighten-3 pa-1 text-left" v-if="Contract__currency_id != Invoice__currency_id">{{ headers[14].text}}</th>
                    <th class="grey lighten-3 pa-1 text-left" v-if="Contract__currency_id != Invoice__currency_id">{{ headers[15].text}}</th>
                    <th class="grey lighten-3 pa-1 text-left" v-if="Contract__currency_id != Invoice__currency_id">{{ $t('message.actions') }}</th>
                    <th class="grey lighten-3 pa-1 text-left" v-if="Contract__currency_id == Invoice__currency_id">{{ headers[10].text}}</th>
                    <th class="grey lighten-3 pa-1 text-left" v-if="Contract__currency_id == Invoice__currency_id">{{ headers[11].text}}</th>
                    <th class="grey lighten-3 pa-1 text-left" v-if="Contract__currency_id == Invoice__currency_id">{{ headers[12].text}}</th>
                    <th class="grey lighten-3 pa-1 text-left" v-if="Contract__currency_id == Invoice__currency_id">{{ headers[13].text}}</th>
                    <th class="grey lighten-3 pa-1 text-left" v-if="Contract__currency_id == Invoice__currency_id">{{ $t('message.actions') }}</th>
                </tr>
            </template>
            <template v-slot:item.lineNumber="{item}">
                <div class="ml-0 font-16px">{{ item.lineNumber }}</div>
            </template>
            <template v-slot:item.Certification.title="{item}">
                <div class="ml-0 font-16px">{{ item.Certification.title }}</div>
            </template>
            <template v-slot:item.Species.title="{item}">
                <div class="ml-0 font-16px">{{ item.Species.title }}</div>
            </template>
            <template v-slot:item.Spec.title="{item}">
                <div class="ml-0 font-16px" v-if="item.type == 'default'">{{ getProduct(item.Productgroup.title, item.Salesitem.description, item.Spec.title) + (item.Salesitem.description != null ? item.Salesitem.description : (item.Spec.title != null ? item.Spec.title : '')) }}</div>
                <div v-else-if="item.type == 'othercharge'">
                    <v-text-field
                            :placeholder="$t('message.otherCharges')"
                            :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                            :value="item.Salesitem.size"
                            class="custom pa-1 force-text-left bg-lightgrey"
                            clearable
                            dense
                            hide-details
                            @change="updateSalesItemRowHandle( item.index, 'Salesitem.size',$event)"
                    />
                </div>
            </template>
            <template v-slot:item.Size.title="{item}">
                <div class="ml-0 font-16px" v-if="item.type == 'default'">{{ item.Size.title }}</div>
            </template>
            <template v-slot:item.Grade.title="{item}">
                <div class="ml-0 font-16px" v-if="item.type == 'default'">{{ item.Grade.title }}</div>
            </template>
            <template v-slot:item.Salesitem.invqty="{item}">
                <v-text-field
                        :placeholder="$t('message.volAndQty')"
                        :rules="[...validationRules.amount]"
                        :value="item.Salesitem.invqty"
                        class="custom pa-1 bg-lightgrey"
                        dense
                        hide-details
                        v-if="item.type == 'default'"
                        @change="updateSalesItemRowHandle( item.index, 'Salesitem.invqty',$event)"
                />
            </template>
            <template v-slot:item.Measurement.title="{ item }">
                <div v-if="item.type == 'default'" class="font-16px">{{ item.Measurement.title.trim() }}</div>
                <div v-else></div>
            </template>
            <template v-slot:item.Salesitem.invprice="{ item }">
                <v-text-field
                        :placeholder="$t('message.price')"
                        :rules="[...validationRules.amount]"
                        :value="item.Salesitem.invprice"
                        class="custom pa-1 bg-lightgrey"
                        dense
                        hide-details
                        v-if="item.type == 'default'"
                        @change="updateSalesItemRowHandle( item.index, 'Salesitem.invprice',$event)"
                />
            </template>
            <template v-slot:item.Salesitem.invoiceamount="{ item }">
                <div v-if="item.type == 'default'" class="font-16px">{{ formatThisNumber(item.Salesitem.invoiceamount,'0,0.00') }}</div>
                <div class="d-inline-flex" v-if="item.type != 'default'" style="margin-right: -20px">
                    <v-text-field
                            :placeholder="$t('message.invoiceAmount')"
                            :rules="[...validationRules.amount]"
                            :value="item.Salesitem.invoiceamount"
                            class="custom pa-1 bg-lightgrey"
                            dense
                            hide-details
                            @change="updateSalesItemRowHandle( item.index, 'Salesitem.invoiceamount',$event)"
                    />
                </div>
            </template>
            <template v-slot:item.Salesitem.invprice_converted="{ item }">
                <div v-if="item.type == 'default'" class="font-16px">{{ formatThisNumber(item.Salesitem.invprice_converted,allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</div>
            </template>
            <template v-slot:item.Salesitem.invoiceamount_converted="{ item }">
                <div class="font-16px">{{ formatThisNumber(item.Salesitem.invoiceamount_converted,allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</div>
            </template>
            <template v-slot:item.Salesitem.poqty="{ item }">
                <div v-if="item.type == 'default'" class="font-16px">
                    {{ formatThisNumber(item.Salesitem.poqty, uofmQty.find((uofm)=>uofm.Measurement.id == item.Salesitem.measurement_id).Measurement.formatPrecision) }}
                </div>
                <div v-else></div>
            </template>
            <template v-slot:item.Salesitem.itemqty="{ item }">
                <div v-if="item.type == 'default'" class="font-16px">
                    {{ formatThisNumber(item.Salesitem.itemqty, uofmQty.find((uofm)=>uofm.Measurement.id == item.Salesitem.measurement_id).Measurement.formatPrecision) }}
                </div>
                <div v-else></div>
            </template>
            <template v-slot:item.Salesitem.unitprice="{item}">
                <div v-if="item.type == 'default'" class="font-16px">{{ formatThisNumber(item.Salesitem.unitprice, allCurrencies.find((currency)=>currency.Currency.id == Contract__currency_id).Currency.formatPrecision) }}</div>
            </template>
            <template v-slot:item.Salesitem.amount="{item}">
                <div v-if="item.type == 'default'" class="font-16px">{{ formatThisNumber(item.Salesitem.amount, allCurrencies.find((currency)=>currency.Currency.id == Contract__currency_id).Currency.formatPrecision) }}</div>
            </template>
            <template v-slot:item.index="{ item }">
                <div class="ml-4" v-if="[0,2].includes(item.Salesitem.chargeto) || item.type == 'othercharge'">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="deleteItem(item.index, item.Salesitem.id )" v-on="on">
                                <v-icon color="grey">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.deleteThisItem') }}</span>
                    </v-tooltip>
                </div>
                <div v-else class="text-center mt-0 font-weight-bold font-16px">{{ $t('message.na') }}</div>
            </template>
            <template v-slot:footer>
                <div class="pt-0 d-flex justify-space-between">
                    <div class="col-4 col-lg-4 col-md-5 pt-5 px-0 d-flex justify-start">
                        <v-btn
                                @click="addOtherChargesItemRow(statePrefix)"
                                color="success"
                                small
                                v-if="Invoice__is_posted != 1"
                        >{{ $t('message.addOtherCharges') }}
                        </v-btn>
                        <v-btn
                                @click="resetInvoiceSalesitems"
                                class="ml-3"
                                color="default"
                                :loading="resettingInvoiceSalesitems"
                                small
                                v-if="false"
                        ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                        </v-btn>
                    </div>
                    <div class="dense-inputs col-8 col-lg-8 col-md-7 pa-0">
                        <v-row justify="end">
                            <v-col cols="2" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.totalQty') }}</div>
                                <div class="text-right font-weight-black d-flex flex-column">
                                    <span v-for="(total,index) in itemsTotalQty" :class="itemsTotalQty.length > 1 && index == 0  ? 'pt-1' : ''">
                                        {{ formatThisNumber(total.Total.qty, uofmQty.find((uofm)=> uofm.Measurement.title == total.Total.uofm.trim()).Measurement.formatPrecision) + ' '+ total.Total.uofm.trim() }}
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.invoiceCurrency') }}</div>
                                <v-container class="ma-0 pa-0">
                                    <v-row no-gutters>
                                        <v-col cols="7" offset="5">
                                            <v-select
                                                    :items="allCurrencies"
                                                    class="custom py-0 mt-0 bg-lightgrey"
                                                    dense
                                                    hide-details
                                                    item-text="Currency.code"
                                                    item-value="Currency.id"
                                                    reverse
                                                    v-model="Invoice__currency_id"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0" v-if="Contract__currency_id != Invoice__currency_id">
                                <div class="text-right font-weight-black">{{ allCurrencies.find((currency)=>currency.Currency.id == Contract__currency_id).Currency.code + ':' + allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.code + ' ' + $t('message.exRate') }}</div>
                                <v-container class="ma-0 py-0 pl-0 pr-0">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="mr-0">
                                            <v-text-field
                                                    :rules="[...validationRules.amount]"
                                                    :value="Invoice__currencyrate"
                                                    class="custom py-0 mt-0 bg-lightgrey"
                                                    dense
                                                    hide-details
                                                    @change="Invoice__currencyrate = $event"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.totalAmount') + (Contract__currency_id != Invoice__currency_id ? '(' + allCurrencies.find((currency)=>currency.Currency.id == Contract__currency_id).Currency.code + ')' : '') }}</div>
                                <div class="text-right font-weight-black">{{ formatThisNumber(itemsTotalAmount, allCurrencies.find((currency)=>currency.Currency.id == Contract__currency_id).Currency.formatPrecision) }}</div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0" v-if="Contract__currency_id != Invoice__currency_id">
                                <div class="text-right font-weight-black">{{ $t('message.totalAmount') + '(' + allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.code +')'}}</div>
                                <div class="text-right font-weight-black">{{ formatThisNumber(itemsTotalAmountConverted, allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</div>
                            </v-col>
                        </v-row>
                        <template v-if="Deposit__amount > 0">
                            <v-row>
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 mt-0 py-0" :class="Contract__currency_id != Invoice__currency_id ? 'offset-md-8 offset-lg-8' : 'offset-md-10 offset-lg-10'">
                                    <div class="text-right font-weight-black">{{ $t('message.totalDownPayment') + (Contract__currency_id != Invoice__currency_id ? '(' + allCurrencies.find((currency)=>currency.Currency.id == Contract__currency_id).Currency.code + ')' : '') }}</div>
                                    <div class="text-right font-weight-black">{{ formatThisNumber(Deposit__amount, allCurrencies.find((currency)=>currency.Currency.id == Contract__currency_id).Currency.formatPrecision) }}</div>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 mt-0 py-0" v-if="Contract__currency_id != Invoice__currency_id">
                                    <div class="text-right font-weight-black">{{ $t('message.totalDownPayment') + '(' + allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.code +')'}}</div>
                                    <div class="text-right font-weight-black d-flex flex-column">
                                        <span>{{ formatThisNumber(Deposit__amount_converted, allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</span>
                                        <span>{{ '(@' + formatThisNumber(Deposit__exchange_rate,'0.0000') + ')' }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 mt-0 py-0" :class="Contract__currency_id != Invoice__currency_id ? 'offset-md-8 offset-lg-8' : 'offset-md-10 offset-lg-10'">
                                    <div class="text-right font-weight-black">{{ $t('message.totalDue') + (Contract__currency_id != Invoice__currency_id ? '(' + allCurrencies.find((currency)=>currency.Currency.id == Contract__currency_id).Currency.code + ')' : '') }}</div>
                                    <div class="text-right font-weight-black">{{ formatThisNumber(itemsTotalDue, allCurrencies.find((currency)=>currency.Currency.id == Contract__currency_id).Currency.formatPrecision) }}</div>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 mt-0 py-0" v-if="Contract__currency_id != Invoice__currency_id">
                                    <div class="text-right font-weight-black">{{ $t('message.totalDue') + '(' + allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.code +')'}}</div>
                                    <div class="text-right font-weight-black">{{ formatThisNumber(itemsTotalDueConverted, allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</div>
                                </v-col>
                            </v-row>
                        </template>
                    </div>
                </div>
            </template>
        </v-data-table>
        <ForexView
            :url="appicUrls.find((url)=>url.key == 'maybankForexRates').url"
            namespace="invoice"
        ></ForexView>
    </div>
</template>

<script>
    import {mapGetters, mapActions, mapMutations} from "vuex";
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import {log, numberFormat} from "../../helpers/helpers";
    import ForexView from "./ForexView";
    import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "../../helpers/vuex-map-fields-prefixed";

    export default {
        name: "InvoiceSalesItemList",
        props: ['updateMode'],
        components: {ForexView},
        data(){
            return {
                currentRowIndex: 0,
                maxNumberLength: 1000000,
                minNumberLength: 0,
                resettingInvoiceSalesitems: false
            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('invoice',{
                Contract__id: 'Invoice.Contract.id',
                Contract__currency_id: 'Invoice.Contract.currency_id',
                Deposit__amount: 'Invoice.Deposit.amount',
                Deposit__amount_converted: 'Invoice.Deposit.amount_converted',
                Deposit__exchange_rate: 'Invoice.Deposit.exchange_rate',
                Invoice__id: 'Invoice.id',
                Invoice__currency_id: 'Invoice.currency_id',
                Invoice__currencyrate: 'Invoice.currencyrate',
                Invoice__is_posted: 'Invoice.is_posted'
            },'statePrefix'),
            ...mapFields('invoice',{
                newInvoiceSaved: 'newInvoiceSaved',
                newInvoiceUnsaved: 'newInvoiceUnsaved',
                updatedInvoiceUnsaved: 'updatedInvoiceUnsaved',
                updatedInvoiceSaved: 'updatedInvoiceSaved',
                openForexView: 'openForexView'
            }),
            ...mapMultiRowFieldsPrefixed('invoicesalesitem',{
                Salesitems: 'Salesitems',
            },'statePrefix'),
            ...mapFieldsPrefixed('invoicesalesitem',{
                itemsCurrencyRate: 'itemsCurrencyRate',
                itemsTotalAmount: 'itemsTotalAmount',
                itemsTotalAmountConverted: 'itemsTotalAmountConverted',
                itemsTotalCommission: 'itemsTotalCommission',
                itemsTotalCost: 'itemsTotalCost',
                itemsTotalMargin: 'itemsTotalMargin',
                itemsTotalQty: 'itemsTotalQty',
                itemsTotalTax: 'itemsTotalTax',
                itemsTotalTaxConverted: 'itemsTotalTaxConverted',
                itemsGrandTotal: 'itemsGrandTotal',
                itemsGrandTotalConverted: 'itemsGrandTotalConverted',
                commissionByUnit: 'commissionByUnit',
                specificationNotes: 'specificationNotes'
            },'statePrefix'),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('producgroup',{
                allProductGroups: 'allProductGroups'
            }),
            ...mapGetters([
                'appicUrls',
                'uofmQty',
                'validationRules'
            ]),
            currencyFormatPrecision () {
                return this.allCurrencies.find((currency) => currency.Currency.id === this.Invoice__currency_id)?.Currency?.formatPrecision
            },
            headers() {
                let headers = [
                    {
                        id: 1,
                        text: this.$t('message.lineNo'),
                        value: 'lineNumber',
                    },
                    {
                        id: 2,
                        text: this.$t('message.certification'),
                        value: 'Certification.title',
                    },
                    {
                        id: 3,
                        text: this.$t('message.species'),
                        value: 'Species.title',
                    },
                    {
                        id: 4,
                        text: this.$t('message.description'),
                        value: 'Spec.title',
                    },
                    {
                        id: 5,
                        text: this.$t('message.size'),
                        value: 'Size.title',
                    },
                    {
                        id: 6,
                        text: this.$t('message.grade'),
                        value: 'Grade.title',
                    },
                    {
                        id: 7,
                        text: this.$t('message.invoiceVolAndQty'),
                        value: 'Salesitem.invqty',
                    },
                    {
                        id: 8,
                        text: this.$t('message.uofm'),
                        value: 'Measurement.title',
                    },
                    {
                        id: 9,
                        text: this.$t('message.invPrice'),
                        value: 'Salesitem.invprice',
                    },
                    {
                        id: 10,
                        text: this.$t('message.invAmt'),
                        value: 'Salesitem.invoiceamount',
                    }
                ]
                if(this.Contract__currency_id != this.Invoice__currency_id){
                    headers.push(
                        {
                            text: this.$t('message.invPrice'),
                            value: 'Salesitem.invprice_converted',
                        }
                    )
                    headers.push(
                        {
                            text: this.$t('message.invAmt'),
                            value: 'Salesitem.invoiceamount_converted',
                        }
                    )
                    headers.push(
                        {
                            text: this.$t('message.purchaseOrderQty'),
                            value: 'Salesitem.poqty',
                        }
                    )
                    headers.push(
                        {
                            text: this.$t('message.contractVolAndQty'),
                            value: 'Salesitem.itemqty',
                        }
                    )
                    headers.push(
                        {
                            text: this.$t('message.contractPrice'),
                            value: 'Salesitem.unitprice',
                        }
                    )
                    headers.push(
                        {
                            text: this.$t('message.contractAmount'),
                            value: 'Salesitem.amount',
                        }
                    )
                    headers.push(
                        {
                            text: this.$t('message.hidePrint'),
                            value: 'index'
                        }
                    )
                } else {
                    headers.push(
                        {
                            text: this.$t('message.purchaseOrderQty'),
                            value: 'Salesitem.poqty',
                        }
                    )
                    headers.push(
                        {
                            text: this.$t('message.contractVolAndQty'),
                            value: 'Salesitem.itemqty',
                        }
                    )
                    headers.push(
                        {
                            text: this.$t('message.contractPrice'),
                            value: 'Salesitem.unitprice',
                        }
                    )
                    headers.push(
                        {
                            text: this.$t('message.contractAmount'),
                            value: 'Salesitem.amount',
                        }
                    )
                    headers.push(
                        {
                            text: this.$t('message.actions'),
                            value: 'index'
                        }
                    )
                }
                return headers
            },
            itemsTotalDue() {
                if(this.Deposit__amount > 0) return (this.itemsTotalAmount - this.Deposit__amount)
                return this.itemsTotalAmount
            },
            itemsTotalDueConverted() {
                if(this.Deposit__amount_converted > 0) return (this.itemsTotalAmountConverted - this.Deposit__amount_converted)
                return this.itemsTotalAmountConverted
            }
        },
        methods: {
            ...mapActions('currency',{
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('invoicesalesitem',{
                convertPriceAndAmount: 'convertPriceAndAmount',
                deleteInvoiceItem: 'deleteInvoiceItem',
                getSalesItemsByContract: 'getSalesItemsByContract',
                updateTotals: 'updateTotals',
            }),
            ...mapMutations('invoicesalesitem',{
                addOtherChargesItemRow: 'addOtherChargesItemRow',
                deleteInvoiceSalesItemRow: 'deleteInvoiceSalesItemRow',
            }),
            async deleteItem(itemIndex, itemId) {
                let _this = this
                if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                    let promise = new Promise((resolve, reject) => {
                        let payload = {
                            index: itemIndex,
                            prefix: _this.statePrefix
                        }
                        this.deleteInvoiceSalesItemRow(payload)
                        resolve('ok')
                    });
                    promise.then(()=>{
                        if(itemId != null){
                            this.deleteInvoiceItem({
                                contract_id: this.Contract__id,
                                salesitem_id: itemId}
                            )
                            .then((response)=>{
                                _this.updateTotals(_this.statePrefix)
                            })
                        } else {
                            _this.updateTotals(_this.statePrefix)
                        }
                    })
                    .finally(()=>{
                        _this.updateTotals(_this.statePrefix)
                    })
                }
            },
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            getProduct(itemProduct, itemDescription, itemSpec){
                if(itemDescription != null && itemDescription != ''){
                    if(itemDescription.includes(itemProduct) == false){
                        return (itemProduct != null ? itemProduct + ', ' : '')
                    } else {
                        return ''
                    }
                } else {
                    if(itemSpec != null && itemSpec != '') {
                        if (itemSpec.includes(itemProduct) == false) {
                            return (itemProduct != null ? itemProduct + ', ' : '')
                        } else {
                            return ''
                        }
                    }
                    return itemProduct != null ? itemProduct : ''
                }
            },
            loadContractSalesItems(contract_id){
                this.getSalesItemsByContract(contract_id)
                    .then(() => {
                        this.updateTotals(this.statePrefix)
                    })
            },
            resetInvoiceSalesitems(){},
            updateSalesItemRowHandle(idx,field,value) {
                var payload = {
                    index: idx,
                    field: field,
                    prefix: this.statePrefix,
                    value: value,
                }
                let promise = new Promise((resolve, reject) => {
                    this.$store.commit('invoicesalesitem/updateSalesItems', payload)
                    resolve('ok')
                });
                let _this = this
                promise.then(() => {
                    this.updateTotals(this.statePrefix)
                })
            },
            uofmPrecision (uofm) {
                if(uofm) {
                    return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
                } else {
                    return '0,0.00'
                }
            },
        },
        watch: {
            Invoice__currencyrate(value){
                this.itemsCurrencyRate = value
                this.convertPriceAndAmount(this.statePrefix)
            }
        },
        created() {
            //ensure itemsCurrencyRate is loaded 2022-04-04
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
            if(this.Invoice__currencyrate){
                this.itemsCurrencyRate = this.Invoice__currencyrate
            }
        },
        mounted(){
            this.$store.watch(
                function(state) {
                    return state.appic.invoicesalesitem.update.Salesitems
                },
                () => {
                    this.updatedInvoiceUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.invoicesalesitem.current.Salesitems
                },
                () => {
                    this.newInvoiceUnsaved = true
                },
                { deep: true }
            )
            this.openForexView = false;
        }
    }
</script>

<style>
.font-16px {
    font-size: 16px !important;
    line-height: 20px;
}
.bg-lightgrey {
    background-color: #F5F5F5;
}
.force-text-left {
    text-align: left !important;
}
.v-text-field__slot input {
    text-align: right;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.v-data-table tbody td {
    vertical-align: middle !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
}
.v-input__append-inner {
    margin-left: 0px !important;
}
</style>